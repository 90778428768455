/* This CSS is also loaded in the screenshot backend frame */

.wh-feedback__dompointer
{
  pointer-events: none;
  position: fixed;
  z-index: 1000000000;
}

.wh-feedback__dompointer::after
{
  background: rgba(255, 0, 0, .1);
  /* we need to use outline, not border, to prevent triggering scrollbars when pointing to body or footer elements */
  outline: 2px solid rgba(255, 0, 0, .9);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
