/* radio */
.wh-radiobutton-wrapper {
  width: 16px;
  height: 16px;
  margin-top: 4px;
}

.wh-radiobutton + .wh-radiobutton-label {
  border: 1px solid #b3b3b3;
  background-color: #fbfbfb;
  outline: none;
  position: relative;
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
  border-radius: 8px;
}

.wh-radiobutton + .wh-radiobutton-label:before {
  position: absolute;
  top: 3px;
  left: 3px;
  content: "";
  background-color: transparent;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 2px;
}

.wh-radiobutton + .wh-radiobutton-label:before {
  border-radius: 4px;
}

.wh-radiobutton:focus + .wh-radiobutton-label {
  border-color: #52aefe;
}

.wh-radiobutton:checked + .wh-radiobutton-label:before {
  background-color: #308fe2;
}

.wh-radiobutton:disabled + .wh-radiobutton-label {
  background-color: #e6e6e6;
  border-color: #b3b3b3;
  opacity: 0.5;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAFUlEQVQImWNgQAInT55sIJXDwMAAAIZhC22Wkht8AAAAAElFTkSuQmCC");
  background-repeat: repeat;
}

.wh-radiobutton:required + .wh-radiobutton-label {
  background-color: #fcf7c9;
}

/* checkbox and radio shared */
.wh-radiobutton {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}