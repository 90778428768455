/* Global page setup */
html {
  font: 12px Roboto, Helvetica, Arial, sans-serif;
  background-color: #ffffff;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  min-height: 100vh;
}

body {
  display: grid;
  grid-template-columns: [desktop] 1fr [tools] 0;
  grid-template-rows: [banner] min-content [topbar] 12px [desktop] 1fr [footerbar] 12px;
  min-height: 100vh;
}

.wh-backend__banner {
  grid-column: desktop/-1;
  grid-row: banner;
  background-color: #fefedd;
  padding: 5px;
  text-align: center;
}

.wh-backend__topbar {
  grid-column: desktop/-1;
  grid-row: topbar;
  background-color: #000000;
  transition: background-color 300ms;
}
.wh-backend__topbar--play {
  background-color: #52aefe;
  transition: none;
}

.wh-backend__bottombar {
  grid-column: desktop/-1;
  grid-row: footerbar;
  background-color: #000000;
}

.wh-backend__main {
  grid-column: desktop;
  grid-row: desktop;
}

.hserror__body {
  margin: 10px 15px;
}

body {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

/* Navigation */
header {
  margin-bottom: 10px;
}

header div.navlevel {
  color: #eeeeee;
  height: 36px;
  padding: 0px 15px 0 0;
  background-color: #3b3b3b;
  font-size: 12px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

header div.navlevel h1 {
  color: inherit;
  font-weight: inherit;
}

header div.navlevel > * {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 15px 5px 15px;
  height: 36px;
  line-height: 26px;
}

header div.navlevel + div.navlevel {
  background-color: #2e2e2e;
}

header div.navlevel + div.navlevel + div.navlevel {
  background-color: #212121;
}

header div.navlevel + div.navlevel + div.navlevel + div.navlevel {
  background-color: #141414;
}

header div.navlevel label {
  min-width: 120px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.05);
}

header div.navlevel nav {
  display: inline-block;
  list-style: none;
}

header div.navlevel nav li {
  display: inline-block;
}

header div.navlevel nav li a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  padding: 0 10px;
  color: #c8c8c8;
}

header div.navlevel nav li a.selected {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #eeeeee;
}

.wh-tollium__feedback {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  cursor: pointer;
  padding: 2px;
  position: fixed;
  right: 0;
  top: 0;
  transition: background 0.1s;
  z-index: 999999999;
}
.wh-tollium__feedback:hover:not(.wh-tollium__feedback--active) {
  background: white;
}
.wh-tollium__feedback--active {
  background: rgba(255, 0, 0, 0.5);
}

.webinterface__fontpreloader {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: none;
  overflow: hidden;
}

.webinterface__fontpreloader__regularfont {
  font-family: Roboto;
}

.webinterface__fontpreloader__monofont {
  font-family: "Roboto Mono";
}