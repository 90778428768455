.wh-backend__main {
  position: relative;
}

.wh-shell__root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-grow: 1;
  background-color: #3b3b3b;
}