.docpanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.docpanel__buttonarea {
  display: flex;
  flex-direction: row;
  right: 0;
  position: absolute;
  top: 0;
}

.docpanel__edit, .docpanel__close {
  cursor: pointer;
}

.docpanel:not(.docpanel--canedit) .docpanel__edit {
  display: none;
}

.docpanel__content {
  border: none;
  display: block;
  height: 100%;
  width: 100%;
}