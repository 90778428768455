textarea {
  resize: none;
  min-width: 20px;
  min-height: 23px;
  position: relative;
  vertical-align: top;
  -webkit-appearance: none;
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  padding: 0 5px;
  color: #000000;
  font-size: 12px;
  line-height: 16px;
  background: #fcfbfb;
  padding: 1px 5px;
}
textarea:focus {
  border-color: #52aefe;
}
textarea[required] {
  background-color: #fcf8d0;
  border-color: #b3b3b3;
}
textarea[required]:focus {
  border-color: #52aefe;
}
textarea[readonly] {
  border-color: #b3b3b3;
  color: rgba(0, 0, 0, 0.5);
  background: url("~@mod-tollium/web/ui/skins/default/bgdisabled.svg") 0 0/48px 48px;
  opacity: 1;
}
textarea[readonly]:focus {
  border-color: #b3b3b3;
}
textarea[readonly]:not(:focus) {
  text-overflow: ellipsis;
}

t-textarea:not(.textarea--hiderequiredifdisabled) textarea[required][readonly] {
  background: #fcf8d0 url("~@mod-tollium/web/ui/skins/default/bgreadonly-disabled.svg") 0 0/48px 48px;
  border-color: #b3b3b3;
  color: rgba(0, 0, 0, 0.5);
}
t-textarea:not(.textarea--hiderequiredifdisabled) textarea[required][readonly]:focus {
  border-color: #52aefe;
}