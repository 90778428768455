t-text {
  margin-top: 5px;
  white-space: nowrap;
}

t-text.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

t-text.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

t-text.wrapped {
  white-space: normal;
}

t-text {
  cursor: text;
}

t-text.label {
  cursor: pointer;
}

html.dompack--debug-rll t-text.label {
  position: relative;
}
html.dompack--debug-rll t-text.label:after {
  position: absolute;
  z-index: 999999999;
  display: block;
  content: "";
  border-bottom: 1px dashed #999;
  width: 1000%;
  margin-top: -3px;
}