/****************************************************************************************************************************
 * Image
 */
.t-image {
  /*  position: relative;*/
  overflow: hidden;
}

.t-image__wrapper {
  position: relative;
  display: table; /* shrink-wrap. IE & Edge unfortunately don't support width: max-content; */
}

.t-image--clickable {
  cursor: pointer;
}

/* FIXME: move part of styling to skin ! */
.t-image__overlay {
  position: absolute;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.8);
  /* FIXME: temporary for focus testing
  &:focus
  {
    background-color: rgba(255,0,255,0.5);
  }
  */
}
.t-image__overlay--enabled {
  cursor: move;
}
.t-image__overlay__dragger--nw, .t-image__overlay__dragger--sw, .t-image__overlay__dragger--ne, .t-image__overlay__dragger--se {
  display: none;
}
.t-image__overlay--enabled .t-image__overlay__dragger--nw, .t-image__overlay--enabled .t-image__overlay__dragger--sw, .t-image__overlay--enabled .t-image__overlay__dragger--ne, .t-image__overlay--enabled .t-image__overlay__dragger--se {
  display: block;
}
.t-image__overlay--selected {
  z-index: 1;
}
.t-image__overlay__dragger--nw {
  cursor: nw-resize;
  position: absolute;
  left: 0;
  top: 0;
}
.t-image__overlay__dragger--sw {
  cursor: sw-resize;
  position: absolute;
  left: 0;
  bottom: 0;
}
.t-image__overlay__dragger--ne {
  cursor: ne-resize;
  position: absolute;
  right: 0;
  top: 0;
}
.t-image__overlay__dragger--se {
  cursor: se-resize;
  position: absolute;
  right: 0;
  bottom: 0;
}
.t-image__overlay:focus, .t-image__overlay:active, .t-image__overlay:hover {
  outline: 0;
  border: 2px solid #308fe2;
}
.t-image__overlay--selected {
  /*background-color: rgba(0, 0, 0, 0.3);*/
  border: 2px solid #308fe2;
  box-shadow: 0 0 3px rgba(1, 1, 1, 0.25);
}
.t-image__overlay__dragger {
  width: 8px;
  height: 8px;
  background-color: #FFFFFF; /* rgba(255, 128, 0, 0.5); */
  opacity: 0;
  transition: 0.2s opacity;
}
.t-image__overlay--selected .t-image__overlay__dragger {
  background-color: #308fe2;
}
.t-image__overlay:hover .t-image__overlay__dragger, .t-image__overlay:active .t-image__overlay__dragger, .t-image__overlay:focus .t-image__overlay__dragger {
  opacity: 1;
  background-color: #308fe2;
  transition: 0.4s opacity;
}

.t-image__overlay__title {
  padding: 8px 5px;
  color: #FFFFFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.65);
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
}