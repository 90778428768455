html.mousehandling--selecting {
  user-select: none;
}

.mousehandling--selectionbase {
  user-select: text;
}

.mousehandling--moveinprogress iframe {
  pointer-events: none;
}