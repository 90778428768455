.wh-slider-holder
{
  min-width: 100px;
  display: inline-flex;
  white-space:nowrap;
}
.wh-slider-holder.vertical
{
  min-width: auto;
  min-height: 100px;
  flex-direction:column;
}
.wh-slider-holder > .wh-slider
{
  position:relative;
  flex-grow:1;
}
.wh-slider-holder:not(.vertical) > .whslider__minvalue
{
  position:relative;
  top:-4px;
  padding-right:10px;
}
.wh-slider-holder:not(.vertical) > .whslider__maxvalue
{
  position:relative;
  top:-4px;
  padding-left:10px;
}
.wh-slider-holder.vertical > .whslider__minvalue
{
  padding-bottom:10px;
}
.wh-slider-holder.vertical > .whslider__maxvalue
{
  padding-top:10px;
}

.wh-slider
{
  position:relative;
  height:1px;
  background-color: #ccc;
}
  .vertical > .wh-slider
, .wh-slider.vertical
{
  height:100%;
  width:1px;
}
.wh-slider > .wh-slider-knob
{
  position:absolute;
  cursor:pointer;
  top:0;
  left:0;
  margin-top:-5px;
  margin-left:-5px;
  height:10px;
  width:10px;
  border-radius:10px;
  background-color:#999;
}
input[readonly] + .wh-slider-holder
{
  pointer-events: none;
}
input[readonly] + .wh-slider-holder .wh-slider-knob
{
  cursor: default;
}
.wh-slider > .wh-slider-rangebar
{
  position:absolute;
  background-color:#999;
  top:-1px;
  height:3px;
}
  .vertical > .wh-slider > .wh-slider-rangebar
, .wh-slider.vertical > .wh-slider-rangebar
{
  top:auto;
  height:auto;
  left:-1px;
  width:3px;
}

.wh-slider > .wh-tick
{
  position: absolute;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #439bbd;
  top: 50%;
}
  .vertical > .wh-slider > .wh-tick
, .wh-slider.vertical > .wh-tick
{
  left: 50%;
}
