@charset "UTF-8";
/****************************************************************************************************************************
 * General settings
 */
/****************************************************************************************************************************
 * Basic layout
 */
/* line 56, http://webhare.moe.sf.b-lex.com/.tollium/ui/skins/default/skin.less */
* {
  border: 0px solid #b3b3b3;
}

t-text a {
  color: #333333;
}

/****************************************************************************************************************************
 * Application tabs (header)
 */
/* line 102, http://webhare.moe.sf.b-lex.com/.tollium/ui/css/shell.less */
.t-apptabs {
  color: #c8c8c8;
  background-color: rgba(255, 255, 255, 0.0035); /* background-color: #3b3b3b; */
  font-size: 12px;
}

.t-apptabs:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #323232;
}

.t-apptab {
  color: #ebebeb;
  position: relative;
}

.t-apptab > * {
  transition: 100ms opacity;
}

.t-apptab:not(.t-apptab--activeapp):not(.t-apptab--dashboard):not(:hover) > * {
  opacity: 0.6;
}

.t-apptab.t-apptab--allowclose .t-apptab__close {
  background: url(closebutton.16x16.png) center center/8px 8px no-repeat;
  width: 12px;
  height: 12px;
  top: 5px;
  right: 2px;
  opacity: 1;
}

.t-apptab.t-apptab--allowclose .t-apptab__close,
.t-apptab.t-apptab--allowclose:not(.t-apptab--activeapp) .t-apptab__close:hover {
  opacity: 1;
}

.t-apptab.t-apptab--allowclose .t-apptab__close:hover,
.t-apptab.t-apptab--allowclose:not(.t-apptab--activeapp) .t-apptab__close {
  opacity: 0.6;
}

.t-apptab:not(.t-apptab--first) {
  border-left-color: #505050;
}

.t-apptab.t-apptab--activeapp:not(.t-apptab--first),
.t-apptab.t-apptab--prevactiveapp:not(.t-apptab--first) {
  border-left-color: #323232;
}

.t-apptab.t-apptab--last {
  border-right-color: #505050;
}

.t-apptab.t-apptab--last.tapptab--activeapp {
  border-right-color: #323232;
}

.t-apptab.t-apptab--activeapp {
  z-index: 1; /* allow us to overwrite the black line generated by header:after */
  background-color: #4a4a4a;
  color: #ebebeb;
}

.t-apptab__title:before {
  font-size: 150%;
  line-height: 1px;
}

.t-apptab.t-apptab--hasissues .t-apptab__title:before {
  content: " ⚑";
  color: #ff0000;
}

.t-apptab.t-apptab--isdebugrunning .t-apptab__title:before {
  content: " ►";
  color: #00ff00;
}

.t-apptab.t-apptab--isdebugpaused .t-apptab__title:before {
  content: " \f04c";
  font: normal normal normal 14px/1 FontAwesome;
  color: #ff0000;
}

.t-apptab__dirty {
  border: 8px solid transparent;
  border-right-color: #52aefe;
  display: none;
  position: absolute;
  top: -8px;
  left: -8px;
  height: 0;
  width: 0;
  transform: rotate(45deg);
}
.t-apptab--dirty .t-apptab__dirty {
  display: block;
}

.t-apptab.t-apptab--activeapp:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #52aefe;
}

.t-apptabs__leftscroll,
.t-apptabs__rightscroll,
.t-apptabs__navtab {
  background-color: #4a4a4a;
}

.t-apptabs__leftscroll:before,
.t-apptabs__rightscroll:before,
.t-apptabs__navtab:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.t-apptabs__leftscroll:hover:before,
.t-apptabs__rightscroll:hover:before,
.t-apptabs__navtab:hover:before {
  opacity: 1;
}

.t-apptabs__leftscroll {
  border-left: 1px solid #505050;
  border-bottom: 1px solid #323232;
  border-right: 1px solid #323232;
}

.t-apptabs__leftscroll:before {
  background: url(arrow-topbar-left.14x24.png) center center/7px 12px no-repeat;
}

.t-apptabs__rightscroll {
  border-right: 1px solid #505050;
  border-bottom: 1px solid #323232;
  border-left: 1px solid #323232;
}

.t-apptabs__rightscroll:before {
  background: url(arrow-topbar-right.14x24.png) center center/7px 12px no-repeat;
}

.t-apptabs__navtab {
  border-left: 1px solid #323232;
  border-bottom: 1px solid #323232;
}

.t-apptabs__navtab:before {
  background: url(3-dots-topbar.6x26.png) center center/3px 13px no-repeat;
}

/* highlight dashboard. #149 */
.t-apptab--dashboard .t-apptab__title {
  font-size: 13px;
  font-weight: 500;
}

/****************************************************************************************************************************
 * Application windows
 */
.t-screen {
  transition: opacity 0.1s;
  background: #ffffff;
}

.t-screen.moving {
  opacity: 0.8;
}

.t-screen:not(.fullscreen) {
  background: #f0f0f0;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

.t-screen .windowheader {
  background-color: #595959;
  color: #ffffff;
}

/* 3 pixelshadow in titlebar */
.t-screen .windowheader:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 3px;
  background-color: #414141;
}

.t-screen .contentnode {
  background-color: #ffffff;
}

/****************************************************************************************************************************
 * Text
 */
t-text.heading {
  color: #308fe2;
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
}

/****************************************************************************************************************************
 * HR (</box> closer)
 */
t-hr:before {
  border-bottom-color: #d0d0d0;
}

/****************************************************************************************************************************
 * Application windows
 */
div.closewindow {
  opacity: 0.7;
  background-image: url(./closebutton.16x16.svg);
}

/* line 201, http://webhare.moe.sf.b-lex.com/.tollium/ui/skins/default/skin.less */
div.closewindow:hover {
  opacity: 1;
}

/****************************************************************************************************************************
 * Menus and menubar
 */
ul.wh-menu:not(.wh-menubar),
.t-selectlist__items {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

ul.wh-menu.wh-menubar {
  background-color: #4a4a4a;
}

ul.wh-menu.wh-menubar > li {
  color: #ffffff;
}

ul.wh-menu.wh-menubar > li.selected {
  color: #000000;
}

ul.wh-menu.wh-menubar > li.divider {
  display: none;
}

/****************************************************************************************************************************
 * Toolbar & toolbar button
*/
/****************************************************************************************************************************
 * Panel
 */
t-panel.isfooter {
  background: #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.panel-area {
  border-color: #b3b3b3;
}

/****************************************************************************************************************************
 * Tab-space, FIXME: maybe this should be renamed to something else?
   Right now this is basically the "buttonbar" above the filmanager's tree and list 8 */
div.tabs-space {
  /* background-color: #f0f0f0; */
}

div.tabs-space button {
  position: relative;
}

div.tabs-space button[aria-pressed=true] {
  background-image: linear-gradient(to bottom, #DDDDDD 0%, #F0F0F0 100%);
}
div.tabs-space button[aria-pressed=true]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #3498ed;
  border-top: 1px solid #95cdfe;
}

div.tabs-space :not(t-textedit) button:hover {
  background-color: #95cdfe;
}

/****************************************************************************************************************************
 * Tabs
 */
t-tabs > nav {
  background-color: #e5e5e5;
}

t-tabs > nav > div > div {
  background-color: #f0f0f0;
  border-color: #c9c9c9;
  border-width: 0 1px 1px 0;
  color: rgba(81, 81, 81, 0.8);
  vertical-align: top;
}

t-tabs > nav > div > div.active {
  background: #ffffff;
  border-bottom-color: #ffffff;
}

t-tabs > nav > div:focus > div.active t-text {
  border-bottom: dotted 1px #999;
}

t-tabs > nav > div > div.active t-text {
  color: #ffffff;
}

t-tabs > nav > div > div.active t-text {
  color: inherit;
}

.t-screen.active t-tabs > nav > div > div.active t-text {
  color: #000000;
}

t-tabs > nav > div > div:hover t-text {
  color: #ffffff;
}

t-tabs > nav > div > div:hover t-text {
  color: inherit;
}

/****************************************************************************************************************************
 * Table
 */
.todd-table__rowgroup > table > tr > td.todd-table__cell--selected,
.todd-table__rowgroup > .todd-table__overlay--selected > *:not(.todd-table__overlayresize) {
  background: #e1e1e1 !important; /* Override any background colors set on table cells and overlays */
}

.todd-table:focus > .todd-table__rowgroup > table > tr > td.todd-table__cell--selected,
.todd-table:focus > .todd-table__rowgroup > .todd-table__overlay--selected > *:not(.todd-table__overlayresize) {
  background: #95cdfe !important; /* Override any background colors set on table cells and overlays */
}

/****************************************************************************************************************************
 * Stacked stabs */
t-tabs.stacked {
  overflow: hidden;
  /* The <div> holds the label in stacked tabs */
  /* The <section> holds a tab panel */
}

t-tabs.stacked > div.tablabel {
  background-color: #3b3b3b;
  height: 28px;
  border-bottom: 1px solid #5a5a5a;
}

t-tabs.stacked > div.tablabel > t-text {
  color: rgba(255, 255, 255, 0.7);
}

t-tabs.stacked > div.tablabel > t-text:after {
  content: "";
  background: url("stackedtab_closed.png");
  position: absolute;
  right: 12px;
  top: 8px;
  width: 8px;
  height: 11px;
}

.t-screen.active t-tabs.stacked > div.tablabel > t-text {
  color: #ffffff;
}

t-tabs.stacked > div.tablabel.active {
  background-color: #0098ee;
  background-image: none;
  border: none;
}

.t-screen.active t-tabs.stacked > div.tablabel.active > t-text {
  color: #ffffff;
}

.t-screen.active t-tabs.stacked > div.tablabel.active > t-text:after {
  content: "";
  background: url("stackedtab_open.png");
  width: 12px;
  height: 8px;
  right: 10px;
  top: 11px;
}

t-tabs.stacked > div.tabsheet {
  position: static;
  z-index: 1;
}

t-tabs.stacked > div.tabsheet.transition {
  transition: width 75ms ease-out, height 75ms ease-out;
}

/* line 840, http://webhare.moe.sf.b-lex.com/.tollium/ui/css/apps.less */
t-tabs.server > div {
  border: none !important;
  top: 0 !important;
}

/* line 845, http://webhare.moe.sf.b-lex.com/.tollium/ui/css/apps.less */
t-tabs.server > div > t-panel {
  margin: 0;
}

/****************************************************************************************************************************
 * RichEdit
 */
/****************************************************************************************************************************
 * Button, Buttongroup
*/
div.t-image.todd--disabled {
  opacity: 0.4;
}

/****************************************************************************************************************************
 * Image edit
 */
t-custom[data-name=imageeditor] .wh-toolbar {
  background: #f3f3f3;
}

t-custom[data-name=imageeditor] .wh-toolbar .wh-toolbar-button {
  color: #333333;
}

t-custom[data-name=imageeditor] .wh-toolbar .wh-toolbar-button:active {
  background-color: #e3e3e3;
  background-image: none;
}

t-custom[data-name=imageeditor] .wh-toolbar .wh-toolbar-button.pressed {
  background-color: #308fe2;
  color: #ffffff;
}

t-custom[data-name=imageeditor] .wh-toolbar .wh-toolbar-button.disabled {
  background-color: transparent;
  border: none;
  color: #333333;
  opacity: 0.45;
}

t-custom[data-name=imageeditor] .wh-toolbar .wh-toolbar-button.disabled img {
  opacity: 0.45;
}

t-custom[data-name=imageeditor] .wh-toolbar .wh-toolbar-button:hover:not(.pressed),
t-custom[data-name=imageeditor] .wh-toolbar .wh-toolbar-button:active:not(.pressed) {
  background-color: rgba(0, 0, 0, 0.11);
  background-image: none;
}

t-custom[data-name=imageeditor] .wh-toolbar .wh-toolbar-button.disabled:hover {
  background-color: transparent;
}

t-custom[data-name=imageeditor] .wh-toolbar .wh-toolbar-separator:before {
  border-color: #dddddd;
}

t-custom[data-name=imageeditor] .wh-cropbox-viewport {
  cursor: move;
}

t-custom[data-name=imageeditor] .wh-cropbox .wh-cropbox-viewport {
  border-color: #308fe2;
}

t-custom[data-name=imageeditor] .wh-cropbox .wh-cropbox-viewport > div {
  background-color: #308fe2;
}

t-custom[data-name=imageeditor] .wh-cropbox-dragger {
  background: none;
  border: 3px solid #308fe2;
  border-radius: 0;
  margin: 0;
}

t-custom[data-name=imageeditor] .wh-cropbox-dragger-nw {
  border-width: 3px 0 0 3px;
  cursor: nw-resize;
}

t-custom[data-name=imageeditor] .wh-cropbox-dragger-ne {
  border-width: 3px 3px 0 0;
  margin-left: -16px;
  cursor: ne-resize;
}

t-custom[data-name=imageeditor] .wh-cropbox-dragger-sw {
  border-width: 0 0 3px 3px;
  margin-top: -16px;
  cursor: sw-resize;
}

t-custom[data-name=imageeditor] .wh-cropbox-dragger-se {
  border-width: 0 3px 3px 0;
  margin-left: -16px;
  margin-top: -16px;
  cursor: se-resize;
}

t-custom[data-name=imageeditor] .wh-refbox-pointer {
  border-color: #308fe2;
}

/****************************************************************************************************************************
 * Progress
 */
progress,
progress[role] {
  /* Turns off styling - not usually needed, but good to know. */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* gets rid of default border in Firefox and Opera. */
  border: none;
  /* Needs to be in here for Safari polyfill so background images work as expected. */
  background-size: auto;
}

/* Polyfill */
progress[role]:after {
  background-image: none; /* removes default background from polyfill */
}

/* Ensure fallback text doesn't appear in polyfill */
progress[role] strong {
  display: none;
}

progress[value] {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid #B3B3B3;
  border-radius: 2px;
  color: #0098ee; /* IE 10 uses color in stead of background-color */
  height: 16px;
  background-color: #ffffff;
}

/* Polyfill */
progress[aria-valuenow]:before {
  background: #ffffff;
}

/* webkit */
progress[value]::-webkit-progress-bar {
  background-color: #ffffff;
}

progress[value]::-webkit-progress-value {
  -webkit-appearance: none;
  appearance: none;
  background-image: -webkit-linear-gradient(-45deg, transparent 33%, rgba(255, 255, 255, 0.1) 33%, rgba(255, 255, 255, 0.1) 66%, transparent 66%), -webkit-linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.15));
  background-color: #0098ee;
  background-size: 35px 20px, 100% 100%, 100% 100%;
  animation: animate-stripes 5s linear infinite;
  transition: 0.2s ease-out;
}

@keyframes animate-stripes {
  100% {
    background-position: -100% 0px;
  }
}
/* firefox */
progress[value]::-moz-progress-bar {
  background-image: -moz-linear-gradient(135deg, transparent 33%, rgba(255, 255, 255, 0.1) 33%, rgba(255, 255, 255, 0.1) 66%, transparent 66%), -moz-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25));
  background-size: 35px 20px, 100% 100%, 100% 100%;
  background-color: #0098ee;
  transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
}

/****************************************************************************************************************************
 * General droptarget styling (hovered element should have position: relative/absolute!)
 */
.droptarget--hover:after {
  border: 2px solid rgba(40, 146, 36, 0.9);
  border-radius: 4px;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

/****************************************************************************************************************************
 * Documentation
 */
.docpanel {
  background-color: #f5f9fd;
  border-left: 1px solid #b3b3b3;
}
.docpanel::after {
  bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  content: "";
  display: block;
  left: -1px;
  position: absolute;
  top: 0;
  width: 1px;
}

.docpanel__buttonarea {
  margin-right: 4px;
  margin-top: 4px;
  overflow: hidden;
  z-index: 0;
}
.docpanel__buttonarea::before {
  background-color: rgba(245, 249, 253, 0.9);
  bottom: 0;
  content: "";
  display: block;
  filter: blur(1px);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.docpanel__edit, .docpanel__close {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  height: 20px;
  width: 20px;
}

.docpanel__edit {
  background-image: url(../../../img/actions/edit.16x16.b.svg);
}

.docpanel__close {
  background-image: url(../../../img/actions/delete.16x16.b.svg);
}