html.wh-tollium--manual {
  background: transparent;
}
html.wh-tollium--manual body {
  margin: 10px;
  display: block;
  min-height: auto;
}
html.wh-tollium--manual .wh-backend__topbar, html.wh-tollium--manual .wh-backend__bottombar {
  display: none;
}