t-codeedit .gutter-content, t-codeedit > textarea {
  font: 9pt/16px "Menlo", "Consolas", "DejaVu Sans Mono", "Courier New", "monospace";
  line-height: 16px;
}

t-codeedit {
  display: block;
  position: relative;
}

t-codeedit div.gutter-background {
  background-color: #e0ebef;
  position: absolute;
}

t-codeedit div.gutter-content {
  border-right: 1px solid #7f7f7f;
  background: transparent;
  overflow: hidden;
  position: absolute;
  text-align: right;
  white-space: pre;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

t-codeedit div.marker-container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: transparent;
  overflow: hidden;
}

t-codeedit div.marker-scroller {
  position: relative;
  overflow: visible;
}

t-codeedit div.marker {
  position: absolute;
}

t-codeedit > textarea {
  border: 0;
  display: block;
  /* FIXME: skinning */
  margin: 0;
  overflow: scroll;
  padding: 0;
  resize: none;
  white-space: pre;
  background: transparent;
}

/* Skinning override */
t-codeedit > textarea[readonly] {
  background: transparent;
}