/* Non-replaced checkboxes in checkboxlists */
input[type=checkbox]:not(.wh-checkbox) {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 2px;
  border: 1px solid #b3b3b3;
  background-color: #fbfbfb;
  cursor: pointer;
  outline: none;
  margin: 5px 0 0;
}
input[type=checkbox]:not(.wh-checkbox):checked {
  background-color: #308fe2;
  background-image: url("~@mod-tollium/web/ui/skins/default/check.16x16.svg");
  border-color: #308fe2;
}
input[type=checkbox]:not(.wh-checkbox):focus-visible::after {
  border: 1px solid white;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
input[type=checkbox]:not(.wh-checkbox):not(:checked):focus-visible {
  border-color: #52aefe;
}
input[type=checkbox]:not(.wh-checkbox):disabled {
  opacity: 0.5;
  cursor: default;
}
input[type=checkbox]:not(.wh-checkbox):not(:checked):disabled {
  background-color: #e6e6e6;
  border-color: #b3b3b3;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAFUlEQVQImWNgQAInT55sIJXDwMAAAIZhC22Wkht8AAAAAElFTkSuQmCC");
  background-repeat: repeat;
}
.list__row__cell input[type=checkbox]:not(.wh-checkbox) {
  margin-top: 3px;
}