.wh-ui-listview {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.wh-ui-listview-content {
  overflow: absolute;
}

.wh-ui-listview>.listheader {
  white-space: nowrap;
  position: relative;
  flex: 0 0 auto;
}

.wh-ui-listview>.listheader>span {
  display: inline-block;
  overflow: hidden;
}

.wh-ui-listview>.listheader>span:after {
  padding-left: 3px;
  vertical-align: middle;
  display: inline-block;
  margin-top: -1px;
}

.wh-ui-listview>.listheader>span.sortascending:after {
  content: "\25B2";
}

.wh-ui-listview>.listheader>span.sortdescending:after {
  content: "\25BC";
}


.wh-ui-listview>.listbodyholder {
  overflow: hidden;
  position: relative;
  flex: 1 0 0;
}

.wh-ui-listview>.listbody {
  overflow: absolute;
}

.wh-ui-listview>.listfooterholder>div {
  position: relative;
}

.wh-ui-listview>.listbodyholder>div>.listrow,
.wh-ui-listview>.listfooterholder>div>.listrow {
  position: absolute;
  width: 100%;
  white-space: nowrap;
}

.wh-ui-listview>.listbodyholder>div>.listrow:not(.unselectable) {
  cursor: pointer;
}

.wh-ui-listview .dragbodyholder>.listrow>span {
  width: 100%;
}

.wh-ui-listview .dragbodyholder>.listrow>span,
.wh-ui-listview>.listbodyholder>div>.listrow>span,
.wh-ui-listview>.listfooterholder>div>.listrow>span {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
}

.wh-ui-listview .dragbodyholder>.listrow>span a,
.wh-ui-listview>.listbodyholder>div>.listrow>span a,
.wh-ui-listview>.listfooterholder>div>.listrow>span a {
  color: inherit;
}

.wh-ui-listview>.listbodyholder>div>.listrow>input {
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.wh-ui-listview>.listbodyholder>.emptytextholder {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}

.wh-ui-listview>.listbodyholder>.emptytextholder>span {
  display: table-cell;
  vertical-align: middle;
  white-space: pre-line;
}

.wh-ui-listview>div>div>.listrow.highlighted:before {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
}

.wh-ui-listview>.listheader>span {
  position: relative;
}

.wh-ui-listview>.listheader>div.splitter {
  position: absolute;
  display: inline-block;
  height: 100%;
}

.wh-ui-listview>.listheader>div.splitter:before {
  content: "";
  position: absolute;
  top: 0px;
  margin-left: -4px;
  bottom: 0px;
  cursor: ew-resize;
  width: 9px;
}

.wh-ui-listview>.listbodyholder>.insertpoint {
  position: absolute;
  font-size: 0;
  margin-top: -1px;
  height: 2px;
  line-height: 0;
  position: absolute;
  width: 100%;
}

.wh-ui-listview>.listbodyholder>.insertpoint>div {
  position: absolute;
  margin-top: -3px;
  margin-left: 2px;
  height: 8px;
  width: 8px;
}
